<template>
    <div class="container" id="container">
        <div class="bl_header">
          <h1>住宅消費エネルギー用途分解プログラム 計算条件の入力</h1>
        </div>
        <div class="p-1">
            <!--タブのコンテンツ部分-->
            <div class="tab-content bl_white">
                <p class="error" v-if="errors.length != 0">
                    {{errors}}
                </p>
                <div id="general" class="tab-pane active">
                    <div class="form-group">
                        <label>世帯の人数</label>
                        <div class="radio_container">
                            <label for="numberOfPeople1">
                                <input
                                    type="radio"
                                    v-model="numberOfPeople"
                                    value="1"
                                    id="numberOfPeople1"
                                />
                                1人
                            </label>
                            <label for="numberOfPeople2">
                                <input
                                    type="radio"
                                    v-model="numberOfPeople"
                                    value="2"
                                    id="numberOfPeople2"
                                />
                                2人
                            </label>
                            <label for="numberOfPeople3">
                                <input
                                    type="radio"
                                    v-model="numberOfPeople"
                                    value="3"
                                    id="numberOfPeople3"
                                />
                                3人
                            </label>
                            <label for="numberOfPeople4">
                                <input
                                    type="radio"
                                    v-model="numberOfPeople"
                                    value="4"
                                    id="numberOfPeople4"
                                />
                                4人
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="A_MR">主たる居室の床面積</label>
                        <input
                            type="number"
                            step="0.00001"
                            class="form-control"
                            v-model="A_MR"
                        />
                        <span class="unit">m<sup>2</sup></span>
                    </div>
                    <div class="form-group">
                        <label for="A_OR">その他の居室の床面積</label>
                        <input
                            type="number"
                            step="0.00001"
                            class="form-control"
                            v-model="A_OR"
                        />
                        <span class="unit">m<sup>2</sup></span>
                    </div>
                    <div class="form-group">
                        <label for="A_A">床面積の合計</label>
                        <input
                            type="number"
                            step="0.00001"
                            class="form-control"
                            v-model="A_A"
                        />
                        <span class="unit">m<sup>2</sup></span>
                    </div>
                    <div class="form-group">
                        <label for="calorificValue">エネルギー源</label>
                        <div class="checkbox_container">
                          <div
                              v-for="(value, name) in calorificValueList"
                              :key="value">
                              <label :for="name">
                                  <input
                                      type ="checkbox"
                                      :id="name"
                                      :value="name"
                                      v-model="calorificValueToAnalyze"
                                  />
                                      {{ name }}
                              </label>
                          </div>
                        </div>
                    </div>
                    <div class="div-input">
                        <button
                            @click="sampleInput"
                            type="button"
                            class="btn"
                        >
                            サンプル
                        </button>
                        <button
                            @click="clearInput"
                            type="button"
                            class="btn"
                        >
                            クリア
                        </button>
                    </div>
                </div>
                <div
                    class="tab-pane-tableWrapper"
                    v-for="analysis in analysisList"
                    :key="analysis.calorificValue">
                    <table class="table table-bordered tbl_month">
                        <thead>
                            <tr>
                                <th></th>
                                <th
                                v-for="i in 12"
                                :key="'month' + i"
                                >{{ i }}月</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-if="analysis.calorificValue == '電気'">{{analysis.calorificValue}}(kWh)</td>
                                <td v-else-if="analysis.calorificValue == '都市ガス'">{{analysis.calorificValue}}(m<sup>3</sup>)</td>
                                <td v-else-if="analysis.calorificValue == '灯油'">{{analysis.calorificValue}}(L)</td>
                                <td v-else-if="analysis.calorificValue == 'LPG'">{{analysis.calorificValue}}(m<sup>3</sup>)</td>
                                <td
                                v-for="(item, index) in analysis.consumption"
                                :key="'month' + (index + 1)"
                                >
                                <input
                                    type="number"
                                    class="form-control"
                                    v-model="analysis.consumption[index]"
                                />
                                </td>
                            </tr>
                            <tr>
                                <td>暖房</td>
                                <td
                                v-for="(item, index) in analysis.heating"
                                :key="'heating' + index"
                                >
                                <input
                                    type="checkbox"
                                    class="form-control"
                                    v-model="analysis.heating[index]"
                                />
                                </td>
                            </tr>
                            <tr v-if="analysis.calorificValue == '電気'">
                                <td>冷房</td>
                                <td
                                v-for="(item, index) in analysis.cooling"
                                :key="'cooling' + index"
                                >
                                <input
                                    type="checkbox"
                                    class="form-control"
                                    v-model="analysis.cooling[index]"
                                />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button
                    @click="calcResult"
                    type="button"
                    class="btn btn-primary btn_kaiseki"
                >
                    解析開始
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'
export default {
  name:"AppForm",
  data() {
      return {
        // サンプル解析paramが設定されるかないかの値
        isSampleInput : false
      }
  },
  computed:{
    ...mapGetters(['data']),
    ...mapComputedProperties([
      'errors',
      'numberOfPeople',
      'A_MR',
      'A_OR',
      'A_A',
      // エネルギー源チェックボックスの選択された値
      'calorificValueToAnalyze',
      // 解析リスト
      'analysisList',
      'calorificValueList',
    ]),
  },
  watch:{
    // エネルギー源チェックボックスの選択された値によって解析するリストを変更する
    calorificValueToAnalyze(val, oldValue) {
          if(val != oldValue && val.length !== 0) {
              let diff = [...new Set([...val.concat(oldValue)])].filter(x => !val.includes(x) || !oldValue.includes(x))
                diff.forEach(cv => {
                    const index = this.analysisList.map(function(elem) { return elem.calorificValue }).indexOf(cv)
                    if (index > -1) {
                        this.analysisList.splice(index, 1)
                    }
                    if(index < 0 || this.isSampleInput) {
                        this.analysisList.push({...{calorificValue: cv},
                        ...{
                            consumption:
                              this.isSampleInput ? cv == '電気' ? [664, 601, 571, 342, 300, 358, 526, 542, 382, 301, 385, 590] : [79.4, 71, 75.7, 60, 52.7, 44.3, 40, 36.1, 41, 52.3, 62.7, 72.2]
                               : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            heating: this.isSampleInput ? [true, true, true, true, false, false, false, false, false, false, true, true] :
                                [false, false, false, false, false, false, false, false, false, false, false, false],
                            cooling: this.isSampleInput && cv == '電気' ? [false, false, false, false, true, true, true, true, true, false, false, false] :
                                [false, false, false, false, false, false, false, false, false, false, false, false],
                            use:{
                                ventilation:true,
                                hotWaterSupply:true,
                                lighting:true,
                                electricAppliance:true,
                                cooking:true,
                            },
                            coeff:{
                                ventilation:1.0,
                                hotWaterSupply:1.0,
                                lighting:1.0,
                                electricAppliance:1.0,
                                cooking:1.0
                        }}})

                        // チェックボックスの並び順に応じてanalysisListをsortする
                        const order = Object.keys(this.calorificValueList)
                        this.analysisList.sort( function (a, b) {
                            const A = a['calorificValue'], B = b['calorificValue']
                            if (order.indexOf(A) > order.indexOf(B)) {
                                return 1
                            } else {
                                return -1
                            }
                        })
                    }
                })
                this.isSampleInput = false
          }
          else this.analysisList.splice(0, this.analysisList.length)
    }
  },
  methods:{
    ...mapActions(['update', 'updateObject', 'calc']),
    // サンプル解析paramを設定する
    sampleInput() {
        this.isSampleInput = true
        this.numberOfPeople = 4

        let arr = this.calorificValueToAnalyze.slice()
        arr.forEach(el => {
            if(el == '電気' || el == '都市ガス')
            {
                let index = this.calorificValueToAnalyze.indexOf(el)
                this.calorificValueToAnalyze.splice(index, 1)
            }
        })

        let chkArr = this.calorificValueToAnalyze.slice()
        chkArr.push('電気','都市ガス')
        this.calorificValueToAnalyze = chkArr
    },
    // 解析paramをクリアする
    clearInput() {
        this.numberOfPeople = 1
        this.A_MR = 29.81
        this.A_OR = 54.34
        this.A_A = 120.08
        this.calorificValueToAnalyze = []
    },
    // 解析する
    calcResult() {
        this.calc()
        if(this.errors == '')
        this.$router.push('result')
    }
  }
}
</script>